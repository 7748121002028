





























































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import { Utils } from '@/app/infrastructures/misc';
import Button from '@/app/ui/components/Button/index.vue'
import DownloadIcon from '@/app/ui/assets/download_icon.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue';
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue';
import { DataObject, HeaderObject } from '@/app/ui/components/DataTableV2/type';
import Badge from '@/app/ui/components/Badge/index.vue';
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import controller from '@/app/ui/controllers/ProgramaticPointController'
import { ProgrammaticParcelPoinAndSaldoInviteeInviter } from '@/domain/entities/Program';
import IconParcelPoint from '@/app/ui/assets/ics_f_parcel_poin.vue';

@Component({
  components: {IconParcelPoint, Badge, DataTableV2, Button, DateTimePickerV2, DownloadIcon, TextInput, PaginationNav}
})
export default class ProgrammaticDataDetail extends Vue {
  controller = controller
  hasData = false
  parameters: {
    page: number
    perPage: number
    keyword: string
    startDate: string | undefined
    endDate: string | undefined
    customerId: number | undefined
    inviteeInviter: string
    periodDate: string
    programmaticPointId: number | undefined
  } = {
    page: 1,
    perPage: 10,
    keyword: '',
    startDate: '',
    endDate: '',
    customerId: undefined,
    inviteeInviter: '',
    periodDate: '',
    programmaticPointId: undefined,
  }
  form: {
    startDate: Date | undefined
    endDate: Date | undefined
  } = {
    startDate: undefined,
    endDate: undefined,
  }
  defaultHeader: HeaderObject[] = [
    {
      title: 'Customer ID',
      customStyle: {minWidth: '140px', maxWidth: '140px'},
    },
    {
      title: 'Customer ID invitee',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'STT',
      customStyle: {minWidth: '165px', maxWidth: '165px'},
    },
    {
      title: 'BKD Date',
      customStyle: {minWidth: '185px', maxWidth: '185px'},
    },
    {
      title: 'POD Date',
      customStyle: {minWidth: '185px', maxWidth: '185px'},
    },
    {
      title: 'Shipping Fee',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'Fixed Amount',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'Percentage',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'Total Earn',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'Status',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'Approval',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
  ]
  headers: HeaderObject[] = []
  dataItems: DataObject[][] = []
  programName = '-'

  created(): void {
    this.headers = this.defaultHeader
    const query = this.$route.query
    if (query && query.programmaticPointId && query.customerId && query.inviteeInviter && query.periodDate) {
      this.parameters.programmaticPointId = Number(query.programmaticPointId)
      this.parameters.customerId = Number(query.customerId)
      this.parameters.inviteeInviter = <string>query.inviteeInviter
      this.parameters.periodDate = <string>query.periodDate
      this.programName = <string>query.programName
    }

    if (this.parameters.inviteeInviter === 'invitee') {
      this.headers.splice(1, 1)
    }

    this.controller.getAllProgrammaticPointAndSaldoInviteInviter(this.parameters)
  }

  private fetchData(isReset = true): void {
    if (isReset) {
      this.parameters.page = 1
    }

    if (!this.form.startDate && this.form.endDate) {
      this.form.startDate = this.form.endDate
      this.parameters.startDate = Utils.formatDate(this.form.startDate.toLocaleDateString(), 'YYYY-MM-DD')
    }

    if (this.form.startDate && !this.form.endDate) {
      this.form.endDate = this.form.startDate
      this.parameters.endDate = Utils.formatDate(this.form.endDate.toLocaleDateString(), 'YYYY-MM-DD')
    }

    this.controller.getAllProgrammaticPointAndSaldoInviteInviter(this.parameters)
  }

  get isInviter(): boolean {
    return this.parameters.inviteeInviter !== 'invitee'
  }

  private setBadgeStatus(props: Record<string, undefined | Record<string, string>>): string {
    if (props.data && props.data.value) {
      let status = (props.data.value || '-').toLowerCase()
      if (['approved', 'disbursed'].includes(status)) {
        return 'success'
      }

      if (['rejected'].includes(status)) {
        return 'cancelled'
      }
    }

    return 'warning'
  }

  @Watch('controller.programmaticPointInviteeInviterData', {deep: true})
  private onProgrammaticPointInviteeInviterDataChange(val: ProgrammaticParcelPoinAndSaldoInviteeInviter[]): void {
    this.hasData = this.controller.programmaticPointInviteeInviterData.length > 0
    this.dataItems = []
    this.hasData = val.length > 0
    val.forEach((data) => {
      this.programName = data.programmaticPointName
      const formatDate = Utils.formatDate(data.podDate, 'DD MMMM YYYY')
      const formatDateBkd = Utils.formatDate(data.bkdDate, 'DD MMMM YYYY')
      const pointEarn = (data.disburseTo.startsWith('PARCEL') ? '' : 'Rp') + Utils.currencyDigit(data.totalEarning)

      const dataItemTable = [
        {
          value: data.customerId,
          customStyle: {minWidth: '140px', maxWidth: '140px'},
        },
        {
          value: data.inviteeCustomerId,
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: data.packageId,
          customStyle: {minWidth: '165px', maxWidth: '165px'},
        },
        {
          value: formatDateBkd === 'Invalid Date' ? '-' : formatDateBkd,
          customStyle: {minWidth: '185px', maxWidth: '185px'},
        },
        {
          value: formatDate === 'Invalid Date' ? '-' : formatDate,
          customStyle: {minWidth: '185px', maxWidth: '185px'},
        },
        {
          value: "Rp" + Utils.currencyDigit(data.shippingFee),
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: "Rp" + Utils.currencyDigit(data.fixedAmount),
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: data.percentage + '%',
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: pointEarn,
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: Utils.toCapitalize(data.status || '-'),
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: data.approvedBy || '-',
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
      ]

      if (!this.isInviter) {
        dataItemTable.splice(1, 1)
      }

      this.dataItems.push(dataItemTable)
    })
  }

  private onStartDateChange(date: Date): void {
    this.form.startDate = date
    if (this.form.startDate && date) {
      this.parameters.startDate = Utils.formatDate(date.toLocaleDateString(), 'YYYY-MM-DD')
    } else {
      this.parameters.startDate = undefined
    }

    if (this.form.endDate) {
      const startDate = Utils.formatDate(date.toLocaleDateString(), 'DD-MMMM-YYYY')
      const endDate = Utils.formatDate(this.form.endDate.toLocaleDateString(), 'DD-MMMM-YYYY')
      if (this.form.endDate < date && startDate !== endDate) {
        this.form.endDate = undefined
        this.parameters.endDate = undefined
      }
    }
  }

  private onEndDateChange(date: Date): void {
    this.form.endDate = date
    if (this.form.endDate && date) {
      this.parameters.endDate = Utils.formatDate(date.toLocaleDateString(), 'YYYY-MM-DD')
    } else {
      this.parameters.endDate = undefined
    }
  }

  private getExportUrl(): void {
    if (this.hasData) {
      const params = {
        params: this.parameters,
      }
      controller.getExportedProgrammaticDataDetail(params)
    }
  }

  beforeDestroy(): void {
    this.dataItems = []
    controller.resetAllProgrammaticInviteeInviterData()
  }

}
